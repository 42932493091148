@import '../../sass/abstracts/variables';

.header {
  min-height: 100vh;
  position: relative;

  @media only screen and (max-width: $layout-breakpoint-small) {
    min-height: 150vh;
  }

  @media only screen and (max-height: $layout-breakpoint-phone) {
    min-height: 150vh;
  }

  .background {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: -1;

      &:first-child {
        z-index: -1;
      }
    }
  }

  .textbox {
    position: absolute;
    left: 3%;
    bottom: 7%;
    width: 40%;
    transform: translateY(150px);
    opacity: 0;

    @media only screen and (max-width: $layout-breakpoint-small) {
      bottom: unset;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 80%;
    }
  }

  .compositionImg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: -1;

    .img {
      height: calc(100% - 20%);
      width: calc(100vw - 63%);
      margin: 0 auto;
      border-top-left-radius: 1400px;
      border-top-right-radius: 1400px;
      overflow: hidden;
      
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
        transform: scale(1.3);
        opacity: 0;
      }

      @media only screen and (max-width: $layout-breakpoint-large) {
        width: calc(100vw - 50%);
      }

      @media only screen and (max-width: $layout-breakpoint-medium) {
        width: calc(100vw - 40%);
      }

      @media only screen and (max-width: $layout-breakpoint-small) {
        width: calc(100vw - 20%);
        height: calc(100% - 30%);
      }

      @media only screen and (max-width: $layout-breakpoint-phone) {
        width: calc(100vw - 10%);
        height: calc(100% - 35%);
      }
    }
  }

  .musicLink {
    display: block;
    position: absolute;
    bottom: 170px;
    right: 170px;
    transform: scale(0);

    @media only screen and (max-width: $layout-breakpoint-small) {
      right: unset;
      left: 50%;
      bottom: -180px;
    }

    &:hover,
    &:focus {
      outline: none;

      .content figure {
        transform: scale(2);

        &::after {
          background-color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;

      figure {
        width: 15rem;
        height: 15rem;
        transform: scale(1);
        border-radius: 50%;
        transition: transform ease-in-out 0.2s;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          height: 100%;
          width: 100%;
          transition: background-color ease-in-out 200ms;
        }
      }

      figure,
      span {
        position: absolute;
      }

      .arrow {
        color: $black;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;

        svg {
          position: static;
        }
      }

      .text {
        animation: rotateText 35s linear infinite;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@keyframes rotateText {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
