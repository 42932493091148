@import '../../sass/abstracts/variables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;

  span {
    color: $black;
  }

  .content {
  }
}
