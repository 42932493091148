@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

html {
  font-size: 62.5%;
  line-height: 1.25;
  body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
  }
}

.heading-primary {
  font-size: clamp(12rem, 12vw + 1rem, 22rem);
  color: $white;
  font-weight: 600;
  margin-bottom: 4rem;
  line-height: 100%;

  @media only screen and (max-width: $layout-breakpoint-phone) {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: $layout-breakpoint-xsmall) {
    font-size: 25vw;
  }

  span {
    font-size: inherit;
  }
}

.heading-secondary {
  font-size: 4rem;
  color: $white;
  font-weight: 600;
  margin-bottom: 2.5rem;
  line-height: 1.5em;

  @include media('<=desktop') {
    font-size: 4rem;
  }

  @include media('<=tablet') {
    font-size: 3.5rem;
  }
}

.paragraph {
  font-weight: 200;
  font-size: 2rem;
  line-height: 2em;

  @media only screen and (max-width: $layout-breakpoint-phone) {
    font-size: 1.5rem;
  }
}

p,
span {
  font-size: 1.8rem;
}

a {
  font-weight: 600;
}
