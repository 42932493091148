// Breakpoints
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1440px;
$layout-breakpoint-small: 960px;
$layout-breakpoint-phone: 600px;
$layout-breakpoint-xsmall: 480px;

// Colors
$background: #030303;
$white: #fff;
$black: #030303;
