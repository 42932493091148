body {
  background-color: $black;
  color: $white;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;

  @include media('<=1560px') {
    width: 1280px;
    min-width: 1280px;
  }
  @include media('<=desktop') {
    width: 1080px;
    min-width: 1080px;
  }
  @include media('<=1080px') {
    width: 100%;
    min-width: 100%;
  }
  .row {
    display: flex;
    height: 100%;
    padding: 0 32px;
    @include media('<=phone') {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.scroll-container {
  height: 100vh;
}
