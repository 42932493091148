@import '../../sass/abstracts/breakpoints';
@import '../../sass/abstracts/variables';

.nav {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  transition: 0.5s ease;
  transition-property: background-color, backdroop-filter, padding;

  a {
    text-decoration: none;
  }

  .pulse {
    animation: pulse 2s infinite;
  }

  .pulseFast {
    animation: pulse 1000ms linear infinite;
  }

  &.background {
    background-color: rgba($black, 0.5);
    backdrop-filter: blur(5px);
    padding: 2rem 5rem;

    @include media('<=desktop') {
      padding: 1.5rem 2rem;
    }

    @include media('<=tablet') {
      padding: 1rem 1.5rem;
    }

    @include media('<=phone') {
      padding: 0.5rem 0.5rem;
    }
  }

  @include media('<=desktop') {
    padding: 2rem;
  }

  @include media('<=tablet') {
    padding: 1.5rem;
  }

  @include media('<=phone') {
    padding: 1rem 0.5rem;
  }

  .cta {
    display: flex;
    align-items: center;
  }

  button,
  a {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: $white;
    padding: 1rem;
    cursor: pointer;
    transition: transform 0.5s ease;

    &:hover,
    &:active {
      transform: scale(1.1);
    }

    svg {
      width: 3rem;
      margin-right: 1rem;

      @include media('<=tablet') {
        width: 2rem;
        margin-right: 0.8rem;
      }

      @include media('<=phone') {
        width: 1.5rem;
        margin-right: 0.8rem;
      }

      path {
        fill: $white;
      }
    }

    span {
      font-weight: 600;
      font-family: inherit;
      font-size: 2rem;

      @include media('<=tablet') {
        font-size: 1.5rem;
      }

      @include media('<=phone') {
        font-size: 1.3rem;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.95);
  }
}
