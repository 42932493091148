@import '../../sass/abstracts/variables';

.main {
  margin: 20rem 0;

  @media only screen and (max-width: $layout-breakpoint-small) {
    margin-top: 35rem;
    margin-bottom: 10rem;
  }

  .container {
    width: min(100%, 130rem);
    margin: 0 auto;
    padding: 0 5rem;

    @media only screen and (max-width: $layout-breakpoint-small) {
      padding: 0 2rem;
    }
  }

  .video {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    height: 70rem;
    border-radius: 6rem;
    overflow: hidden;

    @media only screen and (max-width: $layout-breakpoint-small) {
      height: 50rem;
    }

    @media only screen and (max-width: $layout-breakpoint-phone) {
      height: 40rem;
      border-radius: 3rem;
    }

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      );
      filter: blur(10px);
    }

    figure,
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    figure {
      height: 70%;
      width: 70%;
      border-radius: 3rem;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    button {
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
      color: $white;
      border: none;
      transition: transform 0.3s ease;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 4rem;
      }

      &:hover {
        transform: translate(-50%, -50%) scale(1.2);
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100000;
    
    button {
      position: absolute;
      top: 5rem;
      right: 5rem;
      z-index: 10000;
      height: 7rem;
      width: 7rem;
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        width: 3rem;
        height: auto;
      }

      @media only screen and (max-width: $layout-breakpoint-phone) {
        top: 1rem;
        right: 1rem;
        background-color: $black;

        svg {
          width: 3rem;
        }
      }
    }

    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: black;
    }

    .content {
      padding: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
      width: 90%;
      height: 70%;

      .player {
        width: 100%;
        height: 100%;
      }
    }
  }
}
