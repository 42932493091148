@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/breakpoints';

$move-initial: calc(-25%);
$move-final: calc(-50%);

.container {
  margin-bottom: 20rem;

  .marquee {
    margin-bottom: 5rem;
    padding: 0rem 5rem;

    .text {
      span {
        font-weight: 600;
        letter-spacing: 0.1em;
        font-size: 12rem;
        white-space: nowrap;

        @include media('<=tablet') {
          font-size: 8rem;
        }
      }
    }

    .banner {
      height: 100%;
      min-width: 30rem;
      margin: 0 5rem;
      flex: 1;
      border-radius: 40px;
      overflow: hidden;

      @include media('<=tablet') {
        min-width: 20rem;
        border-radius: 20px;
        margin: 0 3rem;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .experience {
    figure {
      height: 90rem;

      @include media('<=tablet') {
        height: 60rem;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .info {
    display: flex;
    margin: 7rem 0 3rem;

    @include media('<=desktop') {
      flex-direction: column;
      margin: 5rem 3rem 3rem;
    }

    h3,
    p {
      flex: 1;
      margin: 0rem 1rem;
    }
  }

  .shots {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media('<=desktop') {
      margin: 0rem 3rem;
    }

    @include media('<=phone') {
      flex-direction: column;
    }

    figure {
      width: 27rem;
      height: 17rem;

      &:not(:last-child) {
        margin-right: 2rem;
      }

      @include media('<=desktop') {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }

      @include media('<=phone') {
        width: 100%;

        &:not(:last-child) {
          margin-right: 0rem;
          margin-bottom: 1rem;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.marquee {
  position: relative;
  overflow: hidden;
  &.animate .marquee__inner {
    // animation-play-state: running;
  }
  .marquee__inner {
    width: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    transform: translate3d($move-initial, 0, 0);
    animation: marquee 5s linear infinite;
    // animation-play-state: paused;
    @media (min-width: $layout-breakpoint-large) {
      transform: translate3d(calc(-25% + 10vw), 0, 0);
      animation: marquee-lg 5s linear infinite;
      //   animation-play-state: paused;
    }
  }
  .row-title {
    padding: 0 8vw;
    @media (min-width: $layout-breakpoint-large) {
      padding: 0 10vw;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate3d($move-initial, 0, 0);
  }

  100% {
    transform: translate3d($move-final, 0, 0);
  }
}

@keyframes marquee-lg {
  0% {
    transform: translate3d(calc(-25% + 10vw), 0, 0);
  }

  100% {
    transform: translate3d(calc(-50% + 10vw), 0, 0);
  }
}
