@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/breakpoints';

.about {
  margin: 20rem 2rem;

  @include media('<=tablet') {
    margin: 20rem 0rem;
  }

  @media only screen and (max-width: $layout-breakpoint-small) {
    margin-top: 15rem;
  }

  .content {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $layout-breakpoint-small) {
      flex-direction: column;
    }

    .img {
      flex: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      flex: 1;
      padding-left: 7rem;
      margin-top: 5rem;

      @include media('<=desktop') {
        padding-left: 4rem;
      }

      @media only screen and (max-width: $layout-breakpoint-small) {
        padding-left: 0;
        margin-top: 1rem;
        padding: 2rem;
      }
    }
  }
}
