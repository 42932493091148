@import '../../sass/abstracts/breakpoints';
@import '../../sass/abstracts/variables';

.header {
  .body {
    padding: 13rem 5rem 0;

    @include media('<=desktop') {
      padding: 8rem 2rem 0;
    }

    @include media('<=tablet') {
      padding: 6rem 1.5rem 0;
    }

    @include media('<=phone') {
      padding: 7rem 0.5rem 0;
    }

    img {
      width: 100%;
    }
  }

  .gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 80rem;
    grid-gap: 5rem;
    margin-top: 5rem;
    overflow: hidden;
    margin-bottom: 10rem;

    @include media('<=desktop') {
      grid-gap: 2rem;
      padding: 0rem 2rem;
    }

    @include media('<=tablet') {
      grid-template-columns: 1fr;
      margin-top: 2rem;
      grid-auto-rows: minmax(70rem, 90vh);
      padding: 0 1.5rem;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
