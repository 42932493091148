@import '../../sass/abstracts/variables';

.loader {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: $black;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    transition: transform ease-in-out 4s;

    h2 {
      font-size: 100px;
      display: flex;
      align-items: flex-end;
      height: 100px;
      overflow: hidden;
      position: relative;
      z-index: 2;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;

      @media only screen and (max-width: $layout-breakpoint-small) {
        font-size: 80px;
        height: 80px;
      }

      .numbers {
        height: 100%;
        display: flex;
        flex-direction: column;
        line-height: 1;
        transition: transform ease-in-out 4s;
      }
    }

    span {
      font-size: inherit;
      display: block;
    }
  }
}
